<template>
    <div style="position: relative;">
      <a-tree-select v-model="value2" :tree-data="subwayOptions"
        :placeholder="placeholder"
        :show-checked-strategy="strategy"
        tree-checkable
        @change="changeValue"
        :getPopupContainer="getContainer"
        :dropdownStyle="{height:'200px'}"
        :maxTagCount="3"
      />
    </div>
</template>

<script>
  import { TreeSelect } from 'ant-design-vue';
  export default {
    data() {
      return {
        value2: [],
        strategy:TreeSelect.ALL,//TreeSelect.SHOW_PARENT,
        subwayOptions:[],
      }
    },
    props: ["placeholder","cityId","value"],
    watch:{
      cityId(newVal,oldVal){
        if(newVal){
          this.genTreeNode()
        }
      },
      value(newVal,oldVal){
        this.value2=newVal
      }
    },
    methods: {
      genTreeNode() {
        this.axios.get(`/api/base/system/sysDistricts/getAllSub?pId=${this.cityId}`).then(res => {
          this.subwayOptions=res.body
        }).catch(err => {})
      },
      changeValue(value,label, extra){
        this.$emit("update:value",this.value2)
      },
      getContainer(el){
        console.log(el.parentNode)
        return el.parentNode||document.body
      }
    },
  }
</script>

<style lang="less" scoped>
  .treedrop{
    height: 200px;  
  }
</style>
